import React from 'react';
import useNewsById from "../../libs/useNewsById";
import { useParams } from "react-router-dom";
import "./speechdetails.css";

const SpeechDetails = () => {
  const { id } = useParams();
  const { data: newsItem, error, isLoading } = useNewsById(id);
  console.log(newsItem);

  const paragraphs = newsItem?.content
    .split('</p>')
    .filter(paragraph => paragraph.trim())
    .map(paragraph => paragraph + '</p>') || [];

  const totalParagraphs = paragraphs.length;
  const midpoint = Math.ceil(totalParagraphs / 2);

  const firstHalf = paragraphs.slice(0, midpoint);
  const secondHalf = paragraphs.slice(midpoint);

  return (
    <div className="tribunal">
      {isLoading && <p className='loading'>Loading...</p>}
      {!isLoading && error && <div className='error'>An error occurred: {error.message}</div>}
      {!isLoading && !error && !newsItem && <div className='error'>No news item found.</div>}
      {!isLoading && newsItem && (
        <>
          <div className="speech-1">
            <h3>{newsItem.subject}</h3>
            <p>{new Date(newsItem.date).toLocaleDateString()}</p>
          </div>
          <div className="speech-2">
            <div className="wrapper">
              <div className="card-1">
                {firstHalf.map((paragraph, index) => (
                  <div key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
                ))}
              </div>
              <div className="card-2">
                {secondHalf.map((paragraph, index) => (
                  <div key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
                ))}
              </div>
            </div>
            {newsItem.images && newsItem.images[0] && (
              <img src={newsItem.images[0]} alt={newsItem.subject} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SpeechDetails;


