import { NavLink as Link, Outlet, useLocation } from "react-router-dom";
import { Fragment, useContext } from "react";
import { ReactComponent as NothomeIcon } from "../assets/nothome.svg";
import { ReactComponent as DotIcon } from "../assets/dots-vertical.svg";
import { ReactComponent as DothomeIcon } from "../assets/dots-verticalhome.svg";
import {ReactComponent as DapoIcon} from "../assets/dapohomelog.svg"
import { GlobalContext } from "../Context/global_context";
import "./navbar.css";

const Navbar = () => {
  const { isMobile, setIsMobile } = useContext(GlobalContext);
  
  const location = useLocation();

  const NavLinkLCss = ({ isActive }) => {
    return {
      color: isActive ? "#FECB00" : "",
    };
  };

  const navbarClass =
    location.pathname !== "/" && location.pathname !== "/speeches" && location.pathname !== "/about"
      ? "not-home"
      : "";

  return (
    <Fragment>
      <div className={`navbar-container ${navbarClass}`}>
        {navbarClass === "not-home" ? (
          <Link to="/" className="logo" onClick={() => setIsMobile(false)}>
            <NothomeIcon />
          </Link>
        ) : (
          <Link to="/" className="logo" onClick={() => setIsMobile(false)}>
            <DapoIcon />
          </Link>
        )}
        <div
          className={`nav-links ${isMobile ? "mobile-link active" : ""} `}
          onClick={() => setIsMobile(false)}
        >
          <Link to="/about" style={NavLinkLCss}>
            About
          </Link>
          <Link to="/speeches" style={NavLinkLCss}>
            Speeches
          </Link>
          <Link to="/impact" style={NavLinkLCss}>
            Impact
          </Link>
          <Link to="/invest" style={NavLinkLCss}>
            Invest in Ogun State
          </Link>
          <Link to="/media" style={NavLinkLCss}>
            Media
          </Link>
          <Link to="/news" style={NavLinkLCss}>
            News
          </Link>
          <Link to="/contact" className="contact">
            Contact
          </Link>
        </div>
        <Link to="/contact" className="contact">
          Contact
        </Link>
        <div className="mobile-menu">
          {navbarClass === "not-home" ? (
            <DotIcon onClick={() => setIsMobile(!isMobile)} />
          ) : (
            <DothomeIcon onClick={() => setIsMobile(!isMobile)} />
          )}
        </div>
      </div>
      <Outlet />
    </Fragment>
  );
};

export default Navbar;
