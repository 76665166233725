import { useQuery } from 'react-query';
import axios from 'axios';

const fetchFestivalById = async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/media/getfestival/${id}`);
  return response.data;
};

export const useFetchFestivalById = (id) => {
  return useQuery(['festival', id], () => fetchFestivalById(id));
};
