import { useMutation } from 'react-query';

const useSendMessage = () => {
  const sendMessageMutation = useMutation((messageData) => sendMessage(messageData));

  const sendMessage = async (messageData) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/msgs/send`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(messageData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to send message');
    }

    return response.json();
  };

  return {
    sendMessage: sendMessageMutation.mutate,
    isLoading: sendMessageMutation.isLoading,
    isSuccess: sendMessageMutation.isSuccess,
    error: sendMessageMutation.error,
  };
};

export default useSendMessage;
