import { useQuery } from 'react-query';

const fetchLatestSpeech = async () => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/speech/latest`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const useLatestSpeech = () => {
  return useQuery('latestSpeech', fetchLatestSpeech);
};

export default useLatestSpeech;
