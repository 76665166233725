import React from "react";
import { ReactComponent as ArrowIcon } from "../../assets/Layer_13x.svg";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./Youth.css";

const Youthempowerment = () => {
  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);
  return (
    <div className="infrastucture-detail">
      <img src="https://i.ibb.co/x7WDN7T/DSC00123-2.png" alt="" />
      <div className="wrapper">
        <div className="card-1">
          <div className="infrastructure-detail-line"></div>
          <h3 data-aos="zoom-in">YOUTH EMPOWERMENT AND SPORTS</h3>
          <p data-aos="zoom-in">
            youths are the main essence of Gov. Abiodun’s “Building our Future
            Together” Agenda. He has done everything to harness the creative
            potentials of the Ogun State youths since assuming power in 2019.
            His government has continued to seek ways through which the minds of
            young people can be enriched through sound education and creation of
            opportunities. To this end, one of the wonders was the creation of a
            Job Portal within his first 100 days in office to check unemployment
            and ascertain the programmes for employability for the Ogun State
            youth and how to match them with potential mentors and investors
            within and outside the State. On the day that the Job Portal was
            launched, he offered immediate employment to 20 graduates who
            performed best in an open competition. To further underscore his
            resolve to include the youths in the governance process, he
            appointed several of them as his Special Assistants, Senior Special
            Assistants and Special Advisers. His Commissioner for Youth and
            Sports was a dotting young man in his 30s.
          </p>
          <p data-aos="zoom-in">
            The state trained 5,000 unskilled youths and matched them with
            mentors, also allowing them access apprenticeship in different
            industries across the state, including construction, health, and
            transportation. The government provided monthly allowances for them
            during the course of the training under the Ogun State Youth
            Empowerment Scheme (OgunYES). This started in 2020. Subsequently,
            about 5000 teachers were recruited into each of the State Universal
            Basic Education Board (SUBEB) and the Teaching Service Commission
            (TESCOM) through the portal. Determined to continue to tap into this
            raw energy of youth through different approaches, it was no surprise
            when the government earmarked N6 billion for youth empowerment and
            to further partner with corporate organisations in the state to
            carry out various empowerment programmes for its youths.
          </p>
        </div>
        <div className="card-2">
          <ArrowIcon />
        </div>
      </div>
      <div className="infrastructure-detail-con1">
        <img src="https://i.ibb.co/0Yqtzq3/DJI-0853-1.png   " alt="" />
        <img src="https://i.ibb.co/BqFf23p/DSC08306-1.png" alt="" />
      </div>
      <div className="infrastructure-detail-con2">
        <img src="https://i.ibb.co/Q8PsrWD/DJI-0905-1.png" alt="" />
      </div>
      <div className="infrastructure-detail-con3">
        <img src="https://i.ibb.co/HDy8xTN/DJI-0785-1.png" alt="" />
        <img src="https://i.ibb.co/j38XD1N/DJI-0147-1.png" alt="" />
      </div>
    </div>
  );
};

export default Youthempowerment;
