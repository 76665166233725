import { useQuery } from 'react-query';
import axios from 'axios';

const fetchImageById = async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/media/image/${id}`);
  return response.data;
};

export const useFetchImageById = (id) => {
  return useQuery(['image', id], () => fetchImageById(id), {
    enabled: !!id,
  });
};
