import { useState } from "react";
import { ReactComponent as LinkdIcon } from "../assets/la_linkedin-in.svg";
import { ReactComponent as InstaIcon } from "../assets/bi_instagram.svg";
import { ReactComponent as TwitIcon } from "../assets/devicon_twitter.svg";
import { ReactComponent as FaceIcon } from "../assets/gg_facebook.svg";
import { ReactComponent as YouIcon } from "../assets/jam_youtube.svg";
import {ReactComponent as FooterIcon} from "../assets/dapohomelog.svg"
import { Link } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";
import useSubscribeToNewsletter from "../libs/usesubscribe";
import Spinner from "../utils/spinner/spinner";
import "./footer.css";

const Footer = () => {
  const [email, setEmail] = useState('');
  const { subscribe, isLoading, isSuccess, error } = useSubscribeToNewsletter(); 

  const handleSubscribe = async (e) => {
    e.preventDefault(); 
    await subscribe(email);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div className="footer-container">
      <div className="box1">
        <FooterIcon />
        <p>
          Dapo Abiodun coined the I.S.E.Y.A mantra to represent the five major
          pillars on which his development plan for Ogun State hinges, namely
          Infrastructure, Social Welfare and Wellbeing, Education, Youth
          Empowerment and Job Creation, and Agriculture and Food Security
        </p>
        <h3>©2024 All Rights Reserved</h3>
      </div>
      <form onSubmit={handleSubscribe}>
          <label>
            GET EXCLUSIVE OFFERS AND NEWS
            <span>
              <input required type="email" placeholder="Enter your email" value={email} onChange={handleEmailChange} />
              <button disabled={isLoading}>{isLoading ? <Spinner /> : <AiOutlineArrowRight className="icon" />}</button>
            </span>
            {isSuccess && <p>Successfully subscribed!</p>}
            {error && <p>{error.message}</p>}
          </label>
        </form>
      <div className="box2">
        <Link to='/about' >About</Link>
        <Link to='/speeches' >Speeches</Link>
        <Link to='/impact' >Impact</Link>
        <Link to='/invest' >Invest in Ogun State</Link>
        <Link to='/media' >Media</Link>
        <Link to='/news' >News</Link>
        <Link to='https://www.dapoabioduncon.com/' target="blank">Log in</Link>
      </div>
      <div className="box3">
        <form onSubmit={handleSubscribe}>
          <label>
            GET EXCLUSIVE OFFERS AND NEWS
            <span>
              <input required type="email" placeholder="Enter your email" value={email} onChange={handleEmailChange} />
              <button disabled={isLoading}>{isLoading ? <Spinner /> : <AiOutlineArrowRight className="icon" />}</button>
            </span>
            {isSuccess && <p>Successfully subscribed!</p>}
            {error && <p>{error.message}</p>}
          </label>
        </form>
        <div className="social">
            <p>Folllow me on</p>
            <div className="icons">
      <a href="/"><LinkdIcon /></a>
      <a href="https://www.instagram.com/dapoabiodunmfr?igsh=MTY5ZDZmbnpub3A5aQ==" target="_blank" rel="noopener noreferrer"><InstaIcon /></a>
      <a href="https://x.com/dapoabioduncon?s=21&t=uMtAQPLrWeWx9MlhdyKn-w" target="_blank" rel="noopener noreferrer"><TwitIcon /></a>
      <a href="https://x.com/dapoabioduncon?s=21&t=uMtAQPLrWeWx9MlhdyKn-w" target="_blank" rel="noopener noreferrer"><FaceIcon /></a>
      <a href="/"><YouIcon /></a>
    </div>
        </div>
      </div>
      <div className="right">
      <h3>©2024 All Rights Reserved</h3>
      </div>
    </div>
  );
};

export default Footer;
