import React, { useRef } from "react";
import { ReactComponent as ArrowLeft } from "../../assets/ph_arrow-circle-left-light.svg";
import { ReactComponent as ArrowRight } from "../../assets/ph_arrow-circle-right-light.svg";
import useLatestImages from "../../libs/useLatestImages";
import "./media-slider.css";

const MediaSlider = () => {
  const scrollingRef = useRef(null);
  const { data, error, isLoading } = useLatestImages();


  const nextSlide = () => {
    if (scrollingRef.current) {
      scrollingRef.current.scrollLeft += 500;
    }
  };

  const prevSlide = () => {
    if (scrollingRef.current) {
      scrollingRef.current.scrollLeft -= 500;
    }
  };

  return (
    <div className="media-slider-middle">
      <div className="btn">
        <ArrowLeft onClick={prevSlide} />
        <ArrowRight onClick={nextSlide} />
      </div>
      <div className="scrolling" ref={scrollingRef}>
        {isLoading && <div className="loading">Loading leatest...</div>}
        {!isLoading && error && <div className="error">No latest gallary</div>}
        {data && data.map((image) => (
          <div key={image._id} className="box">
            <img src={image.galleryUrl} alt="Gallery" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MediaSlider;


