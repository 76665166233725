import React, { useEffect, useRef } from 'react';

const VideoPlayer = () => {
  const videoUrl = "https://smarttransexpress.s3.eu-north-1.amazonaws.com/Egypt+Presentation_1.mp4";
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    const handleTimeUpdate = () => {
      if (video.currentTime >= 240) { 
        video.pause();
      }
    };

    video.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      video.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, []);

  return (
    <div className="video-player">
      <video ref={videoRef} autoPlay muted > 
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;
