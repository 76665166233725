import React, { useEffect, Fragment, useState } from 'react';
import CustomSlider from "../../Slick_slider_home/react_slick";
import { ReactComponent as ArrowIcon } from "../../assets/solar_arrow-right-line-duotone.svg";
import { ReactComponent as Qoute1Icon } from "../../assets/format-quote-close (1).svg";
import { ReactComponent as QouteIcon } from "../../assets/format-quote-close.svg";
import { ReactComponent as SolarIcon } from "../../assets/rotate_arrow-right-line-duotone.svg";
import { ReactComponent as PlayIcon } from "../../assets/solar_play-outline.svg";
import { ReactComponent as AnnonIcon } from "../../assets/iconamoon_arrow-top-right-1-light.svg";
import { ReactComponent as NothomeIcon } from "../../assets/nothome.svg";
import { MdArrowOutward } from "react-icons/md";
import { BsArrowRight } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import SliderMiddle from "../../pages/Home-middle-slider/slider";
import MobileSlider from "../../pages/react_slick_mobile/reactslick";
import { HiXMark } from "react-icons/hi2";
import useSubscribeToNewsletter from "../../libs/usesubscribe";
import Spinner from "../../utils/spinner/spinner";
import { AiOutlineArrowRight } from "react-icons/ai";
import useLatestNews from "../../libs/useLatestNews";
import useLatestSpeech from "../../libs/useLatestSpeechs";
import VideoPlayer from '../../pages/video/awsfourmins';
import Aos from "aos";
import "aos/dist/aos.css";
import "./home.css";

const Home = () => {
  const [isSubscribePopup, setIscribePopup] = useState(true);
  const [isFirstVisit, setIsFirstVisit] = useState(false);
  const { data, error: latestError, isLoading: latestLoading } = useLatestNews();
  const { data: speech, error: Error, isLoading: Loading } = useLatestSpeech();

  useEffect(() => {
    Aos.init({ duration: 800 });

    const hasVisitedBefore = localStorage.getItem("hasVisitedBefore");
    if (hasVisitedBefore) {
      setIscribePopup(false);
    }
  }, [isFirstVisit]);

  const handleClosePopup = () => {
    setIscribePopup(false);
    setIsFirstVisit(false);
    const hasVisitedBefore = localStorage.getItem("hasVisitedBefore");
    if (!hasVisitedBefore) {
      localStorage.setItem("hasVisitedBefore", "true");
    } else {
      setIscribePopup(false);
    }
  };

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const { subscribe, isLoading, isSuccess, error } = useSubscribeToNewsletter();

  const handleSubscribe = async (e) => {
    e.preventDefault();
    await subscribe(email);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };


  const clamp = (value, min, max) => {
    return Math.min(Math.max(value, min), max);
  };

  const renderFirstParagraph = (content) => {
    if (!content) return null;
    const paragraphs = content.split('<p>');
    if (paragraphs.length > 1) {
      const clampedParagraph = clamp(paragraphs[1].length, 1, 200); 
      return (
        <p dangerouslySetInnerHTML={{ __html: `<p>${paragraphs[1].slice(0, clampedParagraph)}.......` }}></p>
      );
    }
    return null;
  };


  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "https://www.dropbox.com/scl/fi/tufi206pvui6mwglnuk6y/OGSG-Investment-Opportunities-II-2.pdf?rlkey=thaaf1o9shtfv8ctouowpav5o&st=3mh0x4s2&dl=1";
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    link.download = "OGSG-Investment-Opportunities-II-2.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Fragment>
      {isSubscribePopup && (
        <div className="home-sub-container">
          <div className="card" data-aos="fade-down">
            <NothomeIcon />
            <HiXMark className="icon" onClick={handleClosePopup} />
            <div className="in">
              <h1>OPT INTO OUR MAILING LIST</h1>
              <p>Subscribe to receive news letters from Gov Abiodun’s desk</p>
            </div>
            <form onSubmit={handleSubscribe}>
              <label>
                <span>
                  <input
                    required
                    name="email"
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                  <AiOutlineArrowRight className="icon" />
                </span>
              </label>
              <button disabled={isLoading}>
                {isLoading ? <Spinner /> : "SUBSCRIBE"}
              </button>
              {isSuccess && <p className="succ">Successfully subscribed!</p>}
              {error && <p className="error">{error.message}</p>}
            </form>
          </div>
        </div>
      )}
      <div className="home-container">
        <div className="hero-container">
          <div className="overlay">
            <div className="wrapper">
              <h3 data-aos="zoom-in" style={{ fontWeight: "900" }}>
                Impactful Stewardship in Ogun State
              </h3>
              <p data-aos="fade-right">
                Dapo Abiodun <span>CON</span>
              </p>
            </div>
            <div className="scroll">
              <div className="animate">
                <span></span>
              </div>
              <p>SCROLL TO EXPLORE</p>
            </div>
          </div>
          <CustomSlider />
          <MobileSlider />
        </div>

        <div className="we-building-container">
          <h3 data-aos="fade-right">We are building for a better tomorrow.</h3>
          <p data-aos="fade-left">#BuildingOurFutureTogether</p>
        </div>

        <div className="about-dapo">
          <div className="box1">
            <div className="wrapp">
              <span data-aos="zoom-in"></span>
              <p data-aos="zoom-in">About Dapo Abiodun</p>
            </div>
            <div className="inner" data-aos="zoom-in">
              <p>
                Dapo Abiodun began his career with Glock Inc. USA as Cost
                Accountant and worked between 1989-1991.  Thereafter, his
                passion for the motherland made him return to Nigeria and he
                immediately embarked on an entrepreneurship voyage
              </p>
              <p>
                He founded a number of successful businesses.  Until his
                assumption of office as Governor of Ogun State on May 29, 2019,
                he was Chairman and/or Chief Executive of several reputable
                companies which include Crestar Hydrocarbons Limited, OMS-Heyden
                Exploration and Production Limited, Heyden Petroleum Limited
              </p>
            </div>
            <Link to="/about">
              READ MORE <ArrowIcon />{" "}
            </Link>
          </div>
          <div className="box2">
          </div>
        </div>
        <div className="to-give-ogun">
          <div className="box">
            <Qoute1Icon />
            <p data-aos="zoom-in">
              To give Ogun State focused and qualitative governance and to
              create the enabling environment for a public private sector
              partnership
            </p>
            <QouteIcon />
          </div>
        </div>
        <div className="home-speech">
          <div className="box1">
            <h3 data-aos="fade-right">SPEECHES</h3>
            <p data-aos="fade-left">Speeches given by Gov. Dapo Abiodun</p>
          </div>
          <div className="box2">
            {latestLoading && <div className='loading'>Loading...</div>}
            {!latestLoading && latestError && <div className='error'>No Latest speech available</div>}
            {!latestLoading &&
              !latestError &&
              data &&
              data?.map((newsItem) => (
                <div className="box" key={newsItem._id}>
                  <div className="overlay">
                    <Link to={`/new/${newsItem._id}`}>READ MORE</Link>
                  </div>
                  <div className="wrap">
                    <h3>{newsItem?.subject}</h3>
                    {renderFirstParagraph(newsItem?.content)}
                    <span>{new Date(newsItem?.date).toLocaleDateString()}</span>
                  </div>
                  <SolarIcon />
                </div>
              ))}
          </div>
        </div>
        <div className="back-attach"></div>

        <div className="from-promise">
          <div className="from">
            <h3>From Promise to Performance</h3>
            <button onClick={handleDownload}>Download Brochure</button>
          </div>
          <SliderMiddle />
        </div>
        <div className="home-invest">
          <div className="box1">
            <div className="header">
              {" "}
              <span data-aos="zoom-in"></span>{" "}
              <p data-aos="zoom-in">Invest in Ogun State</p>
            </div>
            <div className="inner" data-aos="zoom-in">
              <p>
                Ogun State warmly welcomes investment opportunities. Our goal is
                to continually enhance and refine the state's investment
                environment. OgunInvest plays a pivotal role in facilitating
                investments, overseeing private sector initiatives, and
                optimizing procedures for investors
              </p>
              <p>
                Our steadfast commitment has yielded abundant rewards, with
                existing investments flourishing and new ventures flocking to
                our region. To date, we have successfully attracted 36 new
                investments to Ogun State, totaling over $1 billion in value.{" "}
              </p>
            </div>

            <Link to="/invest">
              LEARN MORE <ArrowIcon />
            </Link>
          </div>
          <div className="box2">
            <div className="inner">
              <p>INVEST IN OGUN STATE PRESENTATION TO EGYPT</p>
              <div className="in">
                <span onClick={() => navigate("/invest")}>
                  <PlayIcon  />
                  WATCH FULL VIDEO
                </span>
                <p>00:15:56</p>
              </div>
            </div>
           <VideoPlayer/>
          </div>
        </div>
        <div className="news-home">
          <div className="header">
            <h1>NEWS</h1>
            <button onClick={() => navigate("/news")}>Read All</button>
          </div>
          <div className="content-container">
            {Loading && <div className='loading'>Loading...</div>}
            {!isLoading && Error && <div className='error'>No latest news available {error}</div>}
            {speech?.map((spe) => {
              return (
                <Link to={`/speech/${spe._id}`} className="box" key={spe._id}>
                  <img src={spe?.images[0]} alt="" />
                  <h3>{spe?.subject}</h3>
                  {renderFirstParagraph(spe?.content)}
                  <button>Read More</button>
                </Link>
              );
            })}
          </div>
          <button onClick={() => navigate("/news")}>Read All</button>
        </div>

        <div className="home-contact">
          <div className="box1">
            <div className="overlay"></div>
            <div className="header">
              <h3 data-aos="zoom-in">Let’s Connect and Build Together</h3>
              <p data-aos="zoom-in">
                Inclusive governance is just a click away
              </p>
              <AnnonIcon />
            </div>
            <Link to="/contact" data-aos="zoom-in">
              Contact <ArrowIcon />
            </Link>
          </div>
          <div className="box2">
            <div className="overlay"></div>
            <div className="header">
              <h3 data-aos="zoom-in">
                Fill in the form, or if you prefer, send us an email
              </h3>
              <p data-aos="zoom-in">
                We’ll get back to you as soon as possible.
              </p>
              <MdArrowOutward className="icon" />
            </div>
            <Link to="/contact" data-aos="zoom-in">
              SEND A MESSAGE <BsArrowRight className="icon" />
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;

