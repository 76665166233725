import { Routes, Route, useLocation } from "react-router-dom";
import { Fragment, useEffect } from "react";
import Navbar from "./Navbar/navbar";
import Home from "./routes/Home/home";
import About from "./routes/About/about";
import Speeches from "./routes/Speeches/speeches";
import Impact from "./routes/Impact/impact";
import InvestInOgunState from "./routes/Invest/invest";
import News from "./routes/News/news";
import Media from "./routes/Media/media";
import Contact from "./routes/Contact/contact";
import Footer from "./Footer/footer";
import Development from "./routes/development/development";
import Newsdetails from "./routes/NewsDetail/newsdetails";
import SpeechDetails from "./routes/Speechdetails/speechdetails";
import Agriculturedetaiil from "./routes/Agriculture/agriculturedetaiil";
import Youthempowerment from "./routes/Youthempowerment/youthempowerment";
import Educationdetail from "./routes/Education/educationdetail";
import Infrastructuredetail from "./routes/InfrastructureDetail/infrastructuredetail";
import Pagenotfound from "./routes/NotFound/pagenotfound";
import ScrollToTopButton from "./scrolltotop/scrolltotop";
import YearDetails from "./Media_By_Year/mediayear/yeardetails";
import FestivalImages from "./Media_By_Year/festival/festivalimages";

const App = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<Navbar />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/speeches" element={<Speeches />} />
          <Route path="/impact" element={<Impact />} />
          <Route path="/development" element={<Development />} />
          <Route path="/invest" element={<InvestInOgunState />} />
          <Route path="/news" element={<News />} />
          <Route path="/media" element={<Media />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/speech/:id" element={<Newsdetails />} />
          <Route path="/new/:id" element={<SpeechDetails />} />
          <Route path="/infrastructuredetail" element={<Infrastructuredetail />} />
          <Route path="/educationdetail" element={<Educationdetail/>} />
          <Route path="/youthempowerment" element={<Youthempowerment />} />
          <Route path="/agriculturedetaiil" element={<Agriculturedetaiil />} />
          <Route path="/year/:id" element={<YearDetails />} />
          <Route path="/festival/:id" element={<FestivalImages />} />
          <Route path="*" element={<Pagenotfound />} />
        </Route>
      </Routes>
      <Footer />
      <ScrollToTopButton/>
    </Fragment>
  );
};

export default App;
