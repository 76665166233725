import { useQuery } from 'react-query';
import axios from 'axios';

const fetchYearById = async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/media/getyear/${id}`);
  return response.data;
};

export const useFetchYearById = (id) => {
  return useQuery(['year', id], () => fetchYearById(id), {
    enabled: !!id, 
  });
};
