import { ReactComponent as LogoIcon } from "../../assets/AboutVectore.svg";
import Dragging from "../../draging_about/about-draging";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./about.css";


const About = () => {
  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);
  return (
    <div className="about-container">
      <div className="hero-container">
        <div className="overley">
          <div className="card">
          <p data-aos="fade-up">
            Dapo Abiodun is a Nigerian politician and businessman. Currently
            serving as the Governor of Ogun State in Nigeria. He assumed office
            on May 29, 2019, after winning the gubernatorial election under the
            platform of the All Progressives Congress (APC). Background includes
            experience in business, particularly in the oil and gas sector,
            before venturing into politics.
          </p>
          </div>
        </div>
      </div>
      <div className="banner">
        <h3 data-aos="fade-right">Where it all began</h3>
        <div className="box1">
          <div className="wrap">
            <p data-aos="zoom-in">
              Prince Adedapo Oluseun Abiodun, CON, was born on Sunday, May 29,
              1960, into the family of Dr Emmanuel and Mrs Victoria Abiodun of
              Iperu-Remo, in Ikenne Local Government of Ogun State.  Both
              parents were teachers. Dapo Abiodun attended Christ’s School Ado
              Ekiti in 1972, St. Joseph’s College, Ondo, in 1977, and later Ondo
              High School, in 1978. His education followed the movement pattern
              of his parents who served in different parts of the then Western
              Region Dapo Abiodun proceeded to the prestigious University of Ife
              (now Obafemi Awolowo University), Ile-Ife, Osun State, where he
              studied Civil Engineering but later transferred to the Kennesaw
              State University in Atlanta, Georgia, United States, where he
              graduated with a BBA in Accounting in 1986.
            </p>
            <p data-aos="zoom-in">
              Dapo Abiodun began his career with Glock Inc. USA as Cost
              Accountant and worked between 1989-1991.  Thereafter, his passion
              for the motherland made him return to Nigeria and he immediately
              embarked on an entrepreneurship voyage. He founded a number of
              successful businesses.  Until his assumption of office as Governor
              of Ogun State on May 29, 2019,{" "}
            </p>
          </div>
          <img src="https://i.ibb.co/CM7mTsv/BLKY6937-1.png" alt="" />
        </div>
        <div className="box2">
          <p data-aos="zoom-in">
            He was Chairman and/or Chief Executive of several reputable
            companies which include Crestar Hydrocarbons Limited, OMS-Heyden
            Exploration and Production Limited, Heyden Petroleum Limited,
            Alarmnet and Innovative Ventures Limited, and First Power Limited.
            As a result of his vast wealth of experience in boardroom
            activities, corporate governance and finance, Dapo Abiodun became a
            regular face at Board Meetings of several blue-chip companies.
          </p>
          <p data-aos="zoom-in">
            His experience transcends the private sector. He also served as a
            member of various Presidential Committees like the Presidential
            Think Tank set up by President Olusegun Obasanjo in 2001. He was
            appointed Chairman, Oil and Gas Committee of Ogun State in 2009 and
            served as Chairman of Depot and Petroleum Product Marketers
            Association (DAPPMA) from 2012 till 2019.
          </p>
        </div>
        <div className="box3">
          <img src="https://i.ibb.co/bzRxxfS/DSC07021-1.png" alt="" />
          <div className="wrap">
            <p data-aos="zoom-in">
              Dapo Abiodun, in 2018, was appointed Chairman of the Corporate
              Affairs Commission (CAC) by President Muhammadu Buhari and led a
              number of reform initiatives that standardized and improved the
              business registration process in the country, leading to a higher
              ease-of-doing-business ranking for the country.  In 2014, he was
              honoured by President Goodluck Ebele Jonathan with the prestigious
              National Award of Member of the Order of the Federal Republic of
              Nigeria (MFR).
            </p>
            <p data-aos="zoom-in">
              In 2018, he was awarded a Doctor of Science in Business
              Development and Administration (Honoris Causa) Degree by the
              Adeleke University, Ede, Osun State and another Doctor of Finance
              (Honoris Causa) Degree by the Ekiti State University, Ado-Ekiti,
              in recognition of his significant positive impacts and immense
              contributions to the development of the nation and humanity.
            </p>
          </div>
        </div>
      </div>

      <div className="politics-container">
        <h3 data-aos="fade-right">
          Dapo Abiodun <span>in Politics</span>
        </h3>
        <div className="wrap">
          <div className="inner">
            <div className="box1">
              <p data-aos="zoom-in">
                On his return from the U.S.A and in line with his commitment to
                the development of the State, and love for his people, he
                contested and won the Ogun East Senatorial seat way back in 1998
                on the platform of the defunct United Nigeria Congress Party,
                emerging as the youngest senator-elect in the country. The
                victory was, however, truncated by political developments in the
                country that aborted the Third Republic and the assumption of
                office of the elected political officeholders.
              </p>
              <p data-aos="zoom-in">
                Undaunted, Dapo Abiodun threw himself into the Ogun East
                Senatorial race as the flagbearer of the All Progressive
                Congress (APC) in 2015 losing narrowly to the winner. Whilst his
                previous political journey might have been a mixed bag, his love
                for his people, commitment to serve them, and passion for the
                development of Ogun State never waned. Therefore, in 2018, he
                made a comeback and contested for and won the governorship
                ticket of the APC in Ogun State in what was arguably the most
                celebrated governorship contest in the 2019 general elections.
              </p>
            </div>
            <div className="box2">
              <p data-aos="zoom-in">
                He later joined the Peoples Democratic Party in 1999 as a
                founding member on which platform he contested in the primaries
                for the governorship ticket in Ogun State in 2002. He emerged as
                the runner-up to the winner of the ticket, who eventually became
                the winner at the general elections.
              </p>
              <p data-aos="zoom-in">
                His message of Building our Future Together resonated and was
                well-received across the State and energized the people
                resulting in a resounding victory at the 2019 polls, thus
                emerging as the 5th elected Governor of Ogun State.
              </p>

              <p data-aos="zoom-in">
                Dapo Abiodun scored 241,670 votes against his closest rival,
                Adekunle Akinlade, of the Allied Peoples Movement (APM), who
                scored 222,153 votes.
              </p>
            </div>
          </div>
          <div className="img"></div>
          <div className="below">
            <div className="box1">
              <p data-aos="zoom-in">
                Gboyega Isiaka of the African Democratic Congress (ADC) polled
                110,422 votes while the candidate of the Peoples Democratic
                Party (PDP), Buruji Kashamu, got 70,290 votes.
              </p>
              <p data-aos="zoom-in">
                Dapo Abiodun won in 11 local government, which include; Ijebu
                Ode, Odogbolu, Odeda, Ijebu North East, Sagamu, Abeokuta South,
                Ijebu East, Remo North, Ogun Water Side, Ikenne and Obafemi
                Owode.
              </p>
              <p data-aos="zoom-in">
                Mr Akinlade of the APM, won in six local government areas which
                are; Ewekoro, Abeokuta North, Egbado South, Ipokia, Ifo and
                Ado-Odo/Ota, while the Governorship Candidate of African
                Democratic Congress, ADC, Gboyega Isiaka won in two local
                government comprising: Imeko Afon and Egbado North.
              </p>
            </div>
            <div className="box2">
              <p data-aos="zoom-in">
                Mr Kashamu, then a serving senator, won a local government,
                Ijebu North.
              </p>
              <p data-aos="zoom-in">
                Dapo Abiodun was sworn in on May 29, 2019, coincidentally his
                59th birthday. The politician is happily married to his
                childhood sweetheart, Bamidele, and the union is blessed with
                wonderful children.
              </p>

              <p data-aos="zoom-in">
                Dapo Abiodun won in 11 local government, which include; Ijebu
                Ode, Odogbolu, Odeda, Ijebu North East, Sagamu, Abeokuta South,
                Ijebu East, Remo North, Ogun Water Side, Ikenne and Obafemi
                Owode.
              </p>

              <p data-aos="zoom-in">
                Mr Akinlade of the APM, won in six local government areas which
                are; Ewekoro, Abeokuta North, Egbado South, Ipokia, Ifo and
                Ado-Odo/Ota, while the Governorship Candidate of African
                Democratic Congress, ADC, Gboyega Isiaka won in two local
                government comprising: Imeko Afon and Egbado North.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="masterclass">
        <div className="box-1">
          <LogoIcon />
        </div>
        <div className="box-2">
          <p data-aos="zoom-in">
            A Masterclass in Achievement. Dapo Abiodun has been honoured with a
            trophy case full of triumphs and multiple awards across different
            sectors in recognition of his exceptional achievements.
          </p>
        </div>
      </div>
      <div className="award">
        <img src="https://i.ibb.co/LPmzQTz/DSC00123-2-2.png" alt="" />
      </div>
      <div className="quote">
        <p data-aos="zoom-in">
          "You are a worthy example of promises made, promises kept. These lofty
          projects could not have materialized without your huge investment and
          commitment to security of lives and property".
        </p>
        <p data-aos="zoom-in">
          - Former President of Federal Republic of Nigeria (2015 - 2023)
          Muhammadu Buhari GCFR
        </p>
      </div>
      <div className="drag">
      <Dragging/>
      </div>
    </div>
  );
};

export default About;
