import { useMutation } from 'react-query';


const useSubscribeToNewsletter = () => {
  const subscribeMutation = useMutation(subscribeToNewsletter);

  const subscribe = async (email) => {
    await subscribeMutation.mutate(email);
  };

  return {
    subscribe,
    isLoading: subscribeMutation.isLoading,
    isSuccess: subscribeMutation.isSuccess,
    error: subscribeMutation.error,
  };
};


const subscribeToNewsletter = async (email) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/newsletter/subscribe`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
    credentials: 'include',
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to subscribe to newsletter');
  }

  return response.json();
};

export default useSubscribeToNewsletter;
