import React from 'react';
import { useParams } from 'react-router-dom';
import useSpeechById from '../../libs/useSpeechById';
import useSpeech from "../../libs/useSpeech";
import { Link } from 'react-router-dom';
import './NewsDetails.css';

const NewsDetails = () => {
  const { id } = useParams();
  const { data, error, isLoading } = useSpeechById(id);
  const { isLoading: isLoadingSpeeches, isError: isErrorSpeeches, data: speechData, error: speechError } = useSpeech();

  return (
    <div className="news-detail">
      {isLoading && <div className='loading'>Loading...</div>}
      {error && <div className='error'>An error occurred: {error.message}</div>}
      {!isLoading && !error && data && (
        <div className="card-1">
          <h4>{new Date(data.date).toLocaleDateString()}</h4>
          <h3>{data.subject}</h3>
          {data.images && data.images.length > 0 && (
            <img src={data.images[0]} alt={data.subject} />
          )}
          <p dangerouslySetInnerHTML={{ __html: data.content }}></p>
        </div>
      )}
      <div className="card-2">
        <h3>More News</h3>
        <div className="wrapper">
          {isLoadingSpeeches && <div className='loading'>Loading...</div>}
          {isErrorSpeeches && <div className='error'>An error occurred: {speechError.message}</div>}
          {!isLoadingSpeeches && !isErrorSpeeches && speechData && (
            speechData.speeches.slice(0, 6).map((speech, index) => (
              <Link to={`/speech/${speech._id}`} className="card" key={speech._id}>
                <span>
                  <p>{new Date(speech.date).toLocaleDateString()}</p>
                  <h3>{speech.subject}</h3>
                </span>
                {speech.images && speech.images.length > 0 && (
                  <img
                    src={speech.images[0]}
                    alt={speech.subject}
                  />
                )}
              </Link>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsDetails;

