import { useQuery } from 'react-query';

const fetchLatestNews = async () => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/new/news/latest`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const useLatestNews = () => {
  return useQuery('latestNews', fetchLatestNews);
};

export default useLatestNews;
