import React from "react";
import { ReactComponent as ArrowIcon } from "../../assets/Layer_13x.svg";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./EducationDetail.css";

const Educationdetail = () => {
  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);
  return (
    <div className="infrastucture-detail">
      <img src="https://i.ibb.co/cXW5skV/DSC00123-2-1.png" alt="" />
      <div className="wrapper">
        <div className="card-1">
          <div className="infrastructure-detail-line"></div>
          <h3 data-aos="zoom-in">EDUCATION & HUMAN CAPITAL DEVELOPMENT</h3>
          <p data-aos="zoom-in">
            I n the province of Ogun State, a thriving educational landscape
            unfolds. As of April 2022, this distinguished state boasts of an
            impressive array of institutions, a testament to its unwavering
            commitment to learning. Within Ogun State’s borders lie 1,564 Public
            Primary Schools, nurturing the young minds of tomorrow.
            Complementing these are 511 Public Secondary Schools, where
            knowledge flourishes and futures take shape. Amongst them stand the
            prestigious Government Science and Technical Colleges, a total of
            seven, instilling scientific prowess and technical expertise in
            eager hearts. And let us not overlook the singular Government
            Technical and Vocational College, cultivating skills and talents
            that ignite the flames of vocational excellence. Let us not also
            forget private education in Ogun State, where 5,864 esteemed Private
            Nursery and Primary Schools grace the educational terrain, paving
            the way for a holistic learning experience. Hand in hand with them
            are 2,043 distinguished Private Secondary Schools, nurturing the
            aspirations of ambitious young individuals. Yet, the state of
            education within Ogun State bears the weight of concern. Whispers of
            unpaid salaries, inadequate funding, and neglected projects have
            cast a shadow upon its noble foundations. Contentious campus
            relocations, hasty appointments, and unsettling strikes have further
            dented the confidence that once thrived. Without swift action, the
            already ailing education system will spiral into deeper turmoil.
            Such a state of affairs is untenable for an enterprise that should
            serve as the bedrock of development, our paramount industry.
          </p>
          <p data-aos="zoom-in">
            Today, Ogun State proudly boasts of the largest congregation of
            public and private universities, which makes Ogun state to Nigeria
            what the revered city of Boston is to the United States of America.
            Alas, this treasured legacy faces gradual erosion due to declining
            educational standards and lackluster performances in national and
            international examinations. To resurrect the grandeur of Ogun
            State’s educational system, urgent, comprehensive, and disruptive
            measures are essential, much like the declaration of a State of
            Emergency. Recognizing that education remains the mightiest
            instrument for effecting social change, the present administration,
            under the distinguished leadership of His Excellency, Prince (Dr)
            Dapo Abiodun (MFR), assumed power on the 29th of May, 2019, with a
            resolute commitment to prioritizing educational matters. Guided by
            an unwavering resolve and propelled by an uncommon innovation, the
            administration has rekindled the flame of educational excellence,
            restoring Ogun State to its rightful place of honour within the
            illustrious community of states. Once more, Ogun stands tall as the
            nation’s capital of education, an epitome of wisdom and knowledge.
          </p>
        </div>
        <div className="card-2">
          <ArrowIcon />
        </div>
      </div>
      <div className="infrastructure-detail-con1">
        <img src="https://i.ibb.co/0Yqtzq3/DJI-0853-1.png   " alt="" />
        <img src="https://i.ibb.co/BqFf23p/DSC08306-1.png" alt="" />
      </div>
      <div className="infrastructure-detail-con2">
        <img src="https://i.ibb.co/Q8PsrWD/DJI-0905-1.png" alt="" />
      </div>
      <div className="infrastructure-detail-con3">
        <img src="https://i.ibb.co/HDy8xTN/DJI-0785-1.png" alt="" />
        <img src="https://i.ibb.co/j38XD1N/DJI-0147-1.png" alt="" />
      </div>
    </div>
  );
};

export default Educationdetail;
