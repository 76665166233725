import { createContext, useState } from "react";

export const GlobalContext = createContext({
  scrollPosition: 0,
  isMobile: false,
});

export const GlobalProvider = ({ children }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const value = { scrollPosition, setScrollPosition, isMobile, setIsMobile };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};
