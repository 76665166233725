import React, { useEffect } from "react";
import { ReactComponent as ArrowIcon } from "../../assets/Layer_13x.svg";
import { ReactComponent as ArrowleftIcon } from "../../assets/Layer_13x (1).svg";
import { useNavigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import "./Impact.css";

const Impact = () => {
  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);

  const navigate = useNavigate();

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href =
      "https://www.dropbox.com/scl/fi/tufi206pvui6mwglnuk6y/OGSG-Investment-Opportunities-II-2.pdf?rlkey=thaaf1o9shtfv8ctouowpav5o&st=3mh0x4s2&dl=1";
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    link.download = "OGSG-Investment-Opportunities-II-2.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownload2 = () => {
    const link = document.createElement("a");
    link.href =
      "https://www.dropbox.com/scl/fi/g33x3nd5sfs7kcqtihxru/OGS-EDPS-2022.pdf?rlkey=oq9ht3npe5mwmluvh38t1exe7&st=jzw4h1d7&dl=1";
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    link.download = "OGSG-Investment-Opportunities-II-2.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownload3 = () => {
    const link = document.createElement("a");
    link.href =
      "https://www.dropbox.com/scl/fi/cnc6j9whadqxq19e5ewas/FPTP-page-layout-31.05.2024-2.pdf?rlkey=fawn4ax1s9qcilyk07ry0r4jm&st=1hw1h5of&dl=1"
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    link.download = "OGSG-Investment-Opportunities-II-2.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="impact">
      <span data-aos="zoom-in">
        <h3>Impact</h3>
      </span>
      <div className="impact-line"></div>
      <div className="wrapper">
        <div className="card-1">
          <p data-aos="zoom-in">
            Before Prince Dapo Abiodun decided to run for the governorship of
            Ogun State, he had observed that the state was not living up to its
            full potential as Nigeria’s Gateway State. Although strategically
            located and sharing borders with Lagos and three other southwestern
            states of Oyo, Osun and Ondo, the Republic of Benin, and being
            Nigeria’s manufacturing capital, Ogun still has more to offer.
            Prince Abiodun wanted to harness the state’s “Gateway” status and
            make it the fastest-growing economy in Nigeria. And this, he has
            achieved in only five years in the saddle.
          </p>
          <p data-aos="zoom-in">
            After he won the quite tumultuous election and became governor in
            2019, he rolled out policies and programmes aimed at giving the
            state a massive turnaround in different sectors. He coined the ISEYA
            mantra to represent the five major pillars on which his development
            plan for the state hinges, namely Infrastructure, Social Welfare and
            Wellbeing, Education, Youth Empowerment and Job Creation, and
            Agriculture and Food Security.
          </p>
        </div>
        <div className="card-3">
          <p>
            After he won the quite tumultuous election and became governor in
            2019, he rolled out policies and programmes aimed at giving the
            state a massive turnaround in different sectors. He coined the ISEYA
            mantra to represent the five major pillars on which his development
            plan for the state hinges, namely Infrastructure, Social Welfare and
            Wellbeing, Education, Youth Empowerment and Job Creation, and
            Agriculture and Food Security.
          </p>
        </div>
      </div>
      <div className="download-con">

      <div className="card-2">
          <div className="impact-overlay">
            <button onClick={handleDownload}>DOWNLOAD</button>
          </div>
        </div>

      <div className="card-3">
          <div className="impact-overlay">
            <button onClick={handleDownload2}>DOWNLOAD</button>
          </div>
        </div>

      <div className="card-4">
          <div className="impact-overlay">
            <button onClick={handleDownload3}>DOWNLOAD</button>
          </div>
        </div>

      </div>
      <div className="iseya"></div>
      <div className="infrastructure-welfare">
        <div className="card-1">
          <div className="image-1" onClick={() => navigate("/development")}>
            <h3>INFRASTRUCTURE DEVELOPMENT</h3>
            <img
              src="https://i.ibb.co/QHhCBNS/DJI-0005-1.png"
              alt="Infrastructure Development"
            />
          </div>
          <div
            className="image-2"
            onClick={() => navigate("/infrastructuredetail")}
          >
            <h3>SOCIAL WELFARE & WELLBEING</h3>
            <img
              src="https://i.ibb.co/6Pj8g2g/DJI-0023-1.png"
              alt="Social Welfare & Wellbeing"
            />
          </div>
        </div>
        <div className="card-2">
          <ArrowIcon />
        </div>
      </div>
      <div className="youth-empowerment">
        <div className="card-2">
          <ArrowleftIcon />
        </div>
        <div className="card-1">
          <div
            className="image-1"
            onClick={() => navigate("/youthempowerment")}
          >
            <h3>YOUTH EMPOWERMENT AND SPORTS</h3>
            <img
              src="https://i.ibb.co/YhW82BB/DSC03935-1.png"
              alt="Youth Empowerment and Sports"
            />
          </div>
          <div className="image-2" onClick={() => navigate("/educationdetail")}>
            <h3>EDUCATION AND HUMAN CAPITAL DEVELOPMENT</h3>
            <img
              src="https://i.ibb.co/rdpDJ88/DSC-0128-2.png"
              alt="Education and Human Capital Development"
            />
          </div>
        </div>
      </div>
      <div
        className="agriculture"
        onClick={() => navigate("/agriculturedetaiil")}
      >
        <h3>AGRICULTURE AND FOOD SECURITY</h3>
        <img
          src="https://i.ibb.co/yVm7jvh/DSC00549-1.png"
          alt="Agriculture and Food Security"
        />
      </div>
    </div>
  );
};

export default Impact;
