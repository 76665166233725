import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as SadEmoji } from "../../assets/sad-emoji-svgrepo-com 1.svg";
import "./Notfound.css";

const Pagenotfound = () => {
  return (
    <div className="page-not-found">
      <SadEmoji />
      <h3>Page not found</h3>
      <p>
        Sorry the page you are looking for cannot be found, Please check the URL
        or try navigating back to the homepage.
      </p>
      <Link to='/'>GO BACK TO HOME PAGE</Link>
    </div>
  );
};

export default Pagenotfound;
