import { useFetchImageById } from "../../libs/useFetchImagById";
import { useFetchFestivalById } from "../../libs/useFetchFestivalById";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ReactComponent as ArrowLeft } from "../../assets/cil_arrow-right (1).svg";
import { ReactComponent as ArrowRight } from "../../assets/cil_arrow-right.svg";
import { Fragment, useRef, useState } from "react";
import { VscClose } from "react-icons/vsc";
import { PiGreaterThanLight } from "react-icons/pi";
import { LiaLessThanSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import "./festival.css";

const FestivalImages = () => {
  const { id } = useParams();
  const { data, isLoading, isError, error } = useFetchFestivalById(id);
  const [overlay, setOverlay] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollingRef = useRef(null);
  const navigate = useNavigate()

  const handleImageClick = (index) => {
    setCurrentIndex(index);
    setOverlay(true);
  };

  const handleNextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < (data?.imageGallery?.length ?? 0) - 1 ? prevIndex + 1 : 0
    );
  };

  const handlePrevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : (data?.imageGallery?.length ?? 0) - 1
    );
  };

  const nextSlide = () => {
    if (scrollingRef.current) {
      scrollingRef.current.scrollLeft += 500;
    }
  };

  const prevSlide = () => {
    if (scrollingRef.current) {
      scrollingRef.current.scrollLeft -= 500;
    }
  };

  return (
    <Fragment>
      {overlay && (
        <div className="image-overlay">
          <VscClose onClick={() => setOverlay(false)} className="close-button" />
          <div className="overlay-content">
            <LiaLessThanSolid className="prev-button" onClick={handlePrevImage} />
            {data?.imageGallery && data.imageGallery[currentIndex] ? (
              <OverlayImage imageId={data.imageGallery[currentIndex]} />
            ) : (
              <p className="loading">Loading image...</p>
            )}
           
            <PiGreaterThanLight className="next-button" onClick={handleNextImage} />
          </div>
        </div>
      )}
      <div className="festival-images">
        <div className="wrap">
        <IoChevronBack  className="back-button" onClick={() => navigate(-1)}/>
          <h1>{data?.name}</h1>
        </div>
        <div className="image-card" ref={scrollingRef}>
          {isLoading && (
            <div className="loading-card">
              <Skeleton count={2} height={100} width={400} />
              <Skeleton count={2} height={100} width={400} />
              <Skeleton count={2} height={100} width={400} />
            </div>
          )}
          {!isLoading && isError && <p className="error">{error.message}</p>}
          {!isLoading && !isError && data?.imageGallery?.length > 0
            ? data.imageGallery.map((imageId, index) => (
                <ImageCard
                  key={index}
                  imageId={imageId}
                  onClick={() => handleImageClick(index)}
                />
              ))
            : !isLoading && <p className="error">No images available for this festival</p>}
        </div>
        {data?.imageGallery.length > 0 && (
          <div className="btn">
          <ArrowRight onClick={prevSlide} />
          <ArrowLeft onClick={nextSlide} />
        </div>
        )}
      </div>
    </Fragment>
  );
};

const OverlayImage = ({ imageId }) => {
  const { data, isLoading, isError, error } = useFetchImageById(imageId);

  if (isLoading) return <p clasName="loading">Loading image...</p>;
  if (isError) return <p className="error">{error.message}</p>;
  return <img src={data.galleryUrl} alt="Gallery" className="overlay-image" />;
};

const ImageCard = ({ imageId, onClick }) => {
  const { data, isLoading, isError, error } = useFetchImageById(imageId);

  return (
    <div className="card-gallery" onClick={onClick}>
      {isLoading && <p className="loading">Getting images...</p>}
      {!isLoading && isError && <p className="error">{error.message}</p>}
      {!isLoading && !isError && data && (
        <img src={data.galleryUrl} alt="Gallery" />
      )}
    </div>
  );
};

export default FestivalImages;
