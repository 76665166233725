import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function MobileSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    pauseOnHover: false,
    lazyLoad: true,
  };
  return (
    <div className="slider-containers">
        <Slider {...settings}>
          <div>
          <img src="https://i.ibb.co/GRDCvZq/Frame-64.png" alt="" />
          </div>
          <div>
            <img src="https://i.ibb.co/kmD5GgD/Frame-65.png" alt="" />
          </div>
          <div>
            <img src="https://i.ibb.co/rbJXVY3/Property-1-Frame-69.png" alt="" />
          </div>
          <div>
          <img src="https://i.ibb.co/qgySnVM/Property-1-Variant4-1.png" alt="" />
          </div>
        </Slider>
    </div>
  );
}

export default MobileSlider;
