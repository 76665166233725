import { useFetchYearById } from "../../libs/useFetchYearById";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import { ReactComponent as GreatIcon } from "../../assets/iconamoon_arrow-right-2-light.svg";
import "./yeardetails.css";

const YearDetails = () => {
  const { id } = useParams();
  const { data: year, isLoading, isError, error } = useFetchYearById(id);
  return (
    <div className="year-details">
      <div className="header">
        <Link to="/media">
          Media <GreatIcon /> <p>{year?.year}</p>{" "}
        </Link>
        <h3>{year?.year}</h3>
      </div>
      {isLoading && (
        <div className="loading-card">
          <Skeleton count={4} height={100} width={400} />
          <Skeleton count={4} height={100} width={400} />
          <Skeleton count={4} height={100} width={400} />
        </div>
      )}
      {isError && <p className="error">Error: {error.message}</p>}
      {!isLoading && !isError && (
        <>
          <div className="festivals">
            {year.festivals.length === 0 ? (
              <p className="error">No festivals available for {year.year}</p> 
            ) : (
              year.festivals.map((festival) => (
                <Link
                  key={festival._id}
                  to={`/festival/${festival._id}`}
                  className="card"
                >
                  <img src={festival.imageCover[0]} alt={festival.name} />
                  <div className="wrap">
                    <h3>{festival.name}</h3>
                  </div>
                </Link>
              ))
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default YearDetails;
