import React, { useState, useEffect } from 'react';
import useSendMessage from '../../libs/useSendMessage';
import Aos from "aos";
import "aos/dist/aos.css";
import './contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({ fullname: '', email: '', content: '' });
  const [successMessageVisible, setSuccessMessageVisible] = useState(false);
  const { sendMessage, isLoading, isSuccess, error } = useSendMessage(); 

  useEffect(() => {
    if (isSuccess) {
      setSuccessMessageVisible(true);
      const timer = setTimeout(() => {
        setSuccessMessageVisible(false);
        setFormData({ fullname: '', email: '', content: '' });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendMessage(formData);
    } catch (error) {
      console.error('Error sending message:', error.message);
    }
  };

  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);

  return (
    <div className="contact-container">
      <div className="header">
        <div className="in">
          <h1 data-aos="fade-right">Contact</h1>
          <p data-aos="fade-right">Write a message to us</p>
        </div>
        <div className="box">
          <p data-aos="fade-left">
            Do you have some question, comment or suggestion about a governance
            issue you would like addressed? Please fill out the Contact form.
          </p>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Your Name"
              name="fullname"
              value={formData.fullname}
              onChange={handleInputChange}
              required
            />
            <input
              type="email"
              placeholder="youremail@gmail.com"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <textarea
              name="content"
              value={formData.content}
              onChange={handleInputChange}
              required
            ></textarea>
            {successMessageVisible && <p>Message sent successfully!</p>}
            {error && <p>Error: {error.message}</p>}
            <button type="submit" disabled={isLoading}>
              {isLoading ? 'Sending...' : 'SEND MESSAGE'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
