import { ReactComponent as PlayIcon } from "../../assets/solar_play-outline.svg";
import { useEffect } from "react";
import Aos from "aos";
import VideoPlayer from "../../pages/video/Awe";
import "aos/dist/aos.css";
import "./invest.css";

const InvestInOgunState = () => {
  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href =
      "https://www.dropbox.com/scl/fi/tufi206pvui6mwglnuk6y/OGSG-Investment-Opportunities-II-2.pdf?rlkey=thaaf1o9shtfv8ctouowpav5o&st=3mh0x4s2&dl=1";
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    link.download = "OGSG-Investment-Opportunities-II-2.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="invest-container">
      <div className="header">
        <h3 data-aos="fade-right">Invest in Ogun State</h3>
        <div className="video-card">
          <div className="overley">
            <h2>INVEST IN OGUN STATE PRESENTATION TO EGYPT</h2>
            <div className="box">
              <button>
                {" "}
                <PlayIcon /> WATCH FULL VIDEO
              </button>
              <p>00:15:56</p>
            </div>
          </div>
          <VideoPlayer />
        </div>
        <div className="wrapper-box">
        <div className="box">
          <p data-aos="zoom-in">
            Ogun State warmly welcomes investment opportunities. Our goal is to
            continually enhance and refine the state's investment environment.
            OgunInvest plays a pivotal role in facilitating investments,
            overseeing private sector initiatives, and optimizing
            procedures for investors.
          </p>
          <p data-aos="zoom-in">
            Our steadfast commitment has yielded abundant rewards, with existing
            investments flourishing and new ventures flocking to our region. To
            date, we have successfully attracted 36 new investments to Ogun
            State, totaling over $1 billion in value.{" "}
          </p>
        </div>

        <div className="card-2">
          <div className="impact-overlay">
            <button onClick={handleDownload}>DOWNLOAD</button>
          </div>
        </div>
        
        </div>
      </div>
    </div>
  );
};

export default InvestInOgunState;

