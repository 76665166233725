import React, { useEffect, useState } from "react";
import useGetOneLatest from "../../libs/useGetOneLatest";
import useSpeech from "../../libs/useSpeech";
import { Link } from "react-router-dom";
import "./news.css";

const News = () => {
  const [page, setPage] = useState(1);
  const [allSpeech, setAllSpeech] = useState([]);
  const { data: latestNews, error: latestNewsError, isLoading: isLoadingLatestNews } = useGetOneLatest();
  const { isLoading: isLoadingSpeeches, isError: isErrorSpeeches, data: speechData, refetch } = useSpeech(page);

  useEffect(() => {
    if (speechData) {
      if (page === 1) {
        setAllSpeech(speechData.speeches);
      } else {
        setAllSpeech((prev) => [...prev, ...speechData.speeches]);
      }
    }
  }, [speechData, page]);

  useEffect(() => {
    refetch();
  }, [page, refetch]);

  const handleLoadMore = () => {
    if (speechData.pagination.next) {
      setPage(speechData.pagination.next.page);
    }
  };

  const renderFirstParagraph = (content) => {
    if (!content) return null;
    const paragraphs = content.split("<p>");
    if (paragraphs.length > 1) {
     
      return  <p dangerouslySetInnerHTML={{ __html: `<p>${paragraphs[1]}` }}></p>

    }
    return null;
  };

  return (
    <div className="new-container">
      <div className="heading">
        <h2>News</h2>
      </div>
      {isLoadingLatestNews && <div className="loading">Loading...</div>}
      {!isLoadingLatestNews && latestNewsError && <p className="error">No news is available</p>}
      {latestNews && (
        <Link to={`/speech/${latestNews[0]._id}`} className="latest" key={latestNews[0]._id}>
          <img src={latestNews[0].images[0]} alt="latest news" />
          <div className="box">
            <h3>{latestNews[0].subject}</h3>
            {renderFirstParagraph(latestNews[0].content)}
            <button>Read More</button>
            <span>{new Date(latestNews[0].date).toLocaleDateString()}</span>
          </div>
        </Link>
      )}

      <div className="news-card">
        {isLoadingSpeeches && <div className="loading">Loading...</div>}
        {!isLoadingSpeeches && isErrorSpeeches && <div className="error">No news is available</div>}
        {allSpeech?.map((speech) => (
          <Link to={`/speech/${speech._id}`} key={speech._id} className="card">
            <img src={speech.images[0]} alt="news" />
            <h3>{speech.subject}</h3>
            {renderFirstParagraph(speech.content)}
            <button>Read More</button>
            <span>{new Date(speech.date).toLocaleDateString()}</span>
          </Link>
        ))}
      </div>

      {speechData?.pagination?.next && (
        <button onClick={handleLoadMore}>Load more</button>
      )}
    </div>
  );
};

export default News;
