import React, { useRef } from "react";
import { ReactComponent as ArrowLeft } from "../../assets/ph_arrow-circle-left-light.svg";
import { ReactComponent as ArrowRight } from "../../assets/ph_arrow-circle-right-light.svg";
import "./slider.css";

const SliderMiddle = () => {
  const scrollingRef = useRef(null);

  const boxData = [
    {
      imgSrc: "https://i.ibb.co/Tv4TThs/Topographic-1.png",
      heading: "01",
      subHeading: "Infrastructural Development",
      description:
        "The most apparent indication of Prince Dapo Abiodun's stellar governance of Ogun State since 29 May 2019, is the distribution of infrastructural projects across all senatorial districts.",
    },
    {
      imgSrc: "https://i.ibb.co/Tv4TThs/Topographic-1.png",
      heading: "02",
      subHeading: "Social welfare & wellbeing",
      description:
        "Social Welfare and Wellbeing involves activities geared toward ensuring quality health and living standard of the of the citizenry.",
    },
    {
      imgSrc: "https://i.ibb.co/Tv4TThs/Topographic-1.png",
      heading: "03",
      subHeading: "Education & Human Capital Development",
      description:
        "In the province of Ogun State, a thriving educational landscape unfolds. Our distinguished state boasts of an impressive array of institutions, a testament to its unwavering commitment to learning.",
    },
    {
      imgSrc: "https://i.ibb.co/Tv4TThs/Topographic-1.png",
      heading: "04",
      subHeading: "Youth Empowerment & Sports",
      description:
        "His government has continued to seek ways through which the minds of young people can be enriched through sound education and creation of opportunities.",
    },
    {
      imgSrc: "https://i.ibb.co/Tv4TThs/Topographic-1.png",
      heading: "05",
      subHeading: "Agriculture & Food Security",
      description:
        "The Agricultural Agenda of the administration addresses the issue of Food Security, Nutrition Security, support to smallholder farmers, Agricultural Industrialization and Linkage of Farmers to the industrial sector.",
    },
  ];

  const nextSlide = () => {
    if (scrollingRef.current) {
      scrollingRef.current.scrollLeft += 500;
    }
  };

  const prevSlide = () => {
    if (scrollingRef.current) {
      scrollingRef.current.scrollLeft -= 500;
    }
  };

  return (
    <div className="Home-slider-middle">
      <div className="btn">
        <ArrowLeft onClick={prevSlide} />
        <ArrowRight onClick={nextSlide} />
      </div>
      <div className="scrolling" ref={scrollingRef}>
        {boxData.map((box, index) => (
          <div key={index} className="box">
            <img src={box.imgSrc} alt="" />
            <h3>{box.heading}</h3>
            <div className="below">
              <h3>{box.subHeading}</h3>
            </div>
            <p>{box.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SliderMiddle;
