import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



function SimpleSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    pauseOnHover: false,
    lazyLoad: true,
  };
  return (
    <div className="slider-container">
        <Slider {...settings}>
          <div>
            <img src="https://i.ibb.co/HXyNx1t/Property-1-Frame-40.png" alt="" />
          </div>
          <div>
            <img src="https://i.ibb.co/d5m2nzb/DSC08985-1-1.png" alt="" />
          </div>
          <div>
            <img src="https://i.ibb.co/Wv08ZKW/Property-1-Frame-42-2x.png" alt="" />
          </div>
          <div>
            <img src="https://i.ibb.co/gJRfBJ0/Property-1-Variant4.png" alt="" />
          </div>
        </Slider>
    </div>
  );
}

export default SimpleSlider;
