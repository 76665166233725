import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../assets/mynaui_search.svg";
import { ReactComponent as SearchIconss } from "../../assets/material-symbols_sort-rounded.svg";
import { MdOutlineArrowOutward } from "react-icons/md";
import useNews from "../../libs/useNews";
import Aos from "aos";
import "aos/dist/aos.css";
import "./speeches.css";

const Speeches = () => {
  const [year, setYear] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [allNews, setAllNews] = useState([]);
  const [filteredNews, setFilteredNews] = useState([]);
  const { isLoading, isError, data,  refetch } = useNews(page);

  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);

  useEffect(() => {
    if (data) {
      if (page === 1) {
        setAllNews(data.news || []);
      } else {
        setAllNews((prev) => [...prev, ...(data.news || [])]);
      }
    }
  }, [data, page]);

  useEffect(() => {
    refetch();
  }, [page, refetch]);

  const filterNews = useCallback(() => {
    let news = [...allNews];
    if (year) {
      news = news.filter((item) => new Date(item.date).getFullYear() === year);
    }
    if (searchQuery) {
      news = news.filter((item) =>
        item.subject.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    setFilteredNews(news);
  }, [allNews, year, searchQuery]);

  useEffect(() => {
    filterNews();
  }, [filterNews]);

  const handleLoadMore = () => {
    if (data.pagination.next) {
      setPage(data.pagination.next.page);
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setPage(1);
  };

  const handleYearFilter = (selectedYear) => {
    setYear(selectedYear);
    setPage(1);
  };

  return (
    <div className="speech-container">
      <div className="hero">
        <div className="overley">
          <p data-aos="zoom-in">Speeches</p>
        </div>
      </div>
      <div className="speech-card">
        <div className="switch">
          <div className="inner">
            <button onClick={() => handleYearFilter(null)}>All</button>
            {[2018, 2019, 2020, 2021, 2022, 2023, 2024].map((year) => (
              <button key={year} onClick={() => handleYearFilter(year)}>
                {year}
              </button>
            ))}
          </div>
          <div className="search">
            <span>
              <input
                type="search"
                placeholder="Search by subject"
                value={searchQuery}
                onChange={handleSearch}
              />
              <SearchIcon />
            </span>
            <SearchIconss />
          </div>
        </div>
        {isLoading && <div className="loading">Loading...</div>}
        {isError && <div className="error">No speeches available</div>}
        {!isLoading && !isError && filteredNews.length === 0 && (
          <p className="year-error">
            {year && !searchQuery
              ? `No speech available for the year ${year}`
              : searchQuery && !year
              ? `No speech found for "${searchQuery}"`
              : `No speech found for the year ${year} and search for "${searchQuery}"`}
          </p>
        )}
        {!isLoading && !isError && filteredNews.length > 0 && (
          <>
            <div className="wrapper">
              {filteredNews.map((item, index) => (
                <div key={`${item._id}-${index}`} className="card">
                  <h3>{item.subject}</h3>
                  <p>{new Date(item.date).toLocaleDateString()}</p>
                  <Link to={`/new/${item._id}`}>
                    Read Now <MdOutlineArrowOutward className="icon" />
                  </Link>
                </div>
              ))}
            </div>
            {data.pagination.next && (
              <button onClick={handleLoadMore}>Load more</button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Speeches;
