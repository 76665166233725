import { useQuery } from 'react-query';
import axios from 'axios';

const fetchYears = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/media/get/allyears`);
  return data;
};

export const useFetchYears = () => {
  return useQuery('years', fetchYears);
};
