import React from "react";
import { ReactComponent as ArrowIcon } from "../../assets/Layer_13x.svg";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./Agriculture.css";

const Agriculturedetaiil = () => {
  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);
  return (
    <div className="infrastucture-detail">
      <img src="https://i.ibb.co/cCSc7vh/DSC00123-2-2.png" alt="" />
      <div className="wrapper">
        <div className="card-1">
          <div className="infrastructure-detail-line"></div>
          <h3 data-aos="zoom-in">AGRICULTURE & FOOD SECURITY</h3>
          <p data-aos="zoom-in">
            Though the fifth of the five pillars on which we are building the
            future of our dear state together, Agriculture and Food Security is
            by no means the least important. Rather it is one of our strongest
            pillars on which we have been putting much effort. And the result is
            heartwarming. According to a popular Yoruba saying, once the issue
            of hunger is resolved, the problem of poverty is half solved. With
            this at the back of our minds, coupled with the potential of
            agriculture to create quick employment, this administration resolved
            from Day One to give greater priority to agriculture, more so as we
            are an agrarian state. With a focus on those areas of agriculture
            where we have a comparative advantage, like cassava cultivation,
            rice farming, poultry (eggs and broilers), aquaculture (fish
            farming, etc.), leafy vegetables, lemon grass oil and a host of
            others, we have in the last four years made agriculture more
            profitable, lucrative and attractive, especially to our youths some
            of whom our deliberate agro-friendly policies have turned into
            successful agri-preneurs.
          </p>
          <p data-aos="zoom-in">
            The Agricultural Agenda of the administration addresses the issue of
            Food Security, Nutrition Security, support to smallholder farmers,
            Agricultural Industrialisation and Linkage of Farmers to the
            industrial sector. It also promotes job creation and livelihood
            improvement. Support for the agricultural sector over the last four
            years has been tailored towards a strategic partnership with the
            private sector and all agencies, be it local, national or
            international, that has the mandate of supporting agriculture,
            employment generation and economic development. Key special
            agricultural projects such as the Anchor Borrowers Programme, which
            is a linkage programme to leverage the Central Bank of Nigeria
            Anchor Borrowers Programme (ABP) have been highly successful,
            supporting 4,565 Cassava and 1,162 rice farmers as well as local
            value chain actors.
          </p>
        </div>
        <div className="card-2">
          <ArrowIcon />
        </div>
      </div>
      <div className="infrastructure-detail-con1">
        <img src="https://i.ibb.co/0Yqtzq3/DJI-0853-1.png   " alt="" />
        <img src="https://i.ibb.co/BqFf23p/DSC08306-1.png" alt="" />
      </div>
      <div className="infrastructure-detail-con2">
        <img src="https://i.ibb.co/Q8PsrWD/DJI-0905-1.png" alt="" />
      </div>
      <div className="infrastructure-detail-con3">
        <img src="https://i.ibb.co/HDy8xTN/DJI-0785-1.png" alt="" />
        <img src="https://i.ibb.co/j38XD1N/DJI-0147-1.png" alt="" />
      </div>
    </div>
  );
};

export default Agriculturedetaiil;
