import { useQuery } from 'react-query';

const fetchSpeechById = async (id) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/speech/speeches/${id}`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const useSpeechById = (id) => {
  return useQuery(['news', id], () => fetchSpeechById(id));
};

export default useSpeechById;
