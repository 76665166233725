import MediaSlider from "../../pages/Media-slider/media-slider";
import { Link } from "react-router-dom";
import { useFetchYears } from "../../libs/useFetchYear";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./media.css";

const Media = () => {
  const {
    data: years,
    isLoading: isYearsLoading,
    isError: isYearsError,
  } = useFetchYears();

  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);

  return (
    <div className="media-container">
      <div className="header">
        <h3 data-aos="fade-right">Media</h3>
      </div>
      <div className="scrolling-media">
        <p>Explore Latest</p>
        <MediaSlider />
      </div>

      <div className="explore-year">
        <h3>Explore by year</h3>
        <div className="year-card">
          {isYearsLoading && <p className="loading">Loading years...</p>}
          {isYearsError && <p className="error">No years available</p>}
          {!isYearsLoading && !isYearsError && years && years.map((yearData) => (
            <Link to={`/year/${yearData._id}`} key={yearData._id}>
              <img src={yearData.imageCover[0]} alt={`Cover for ${yearData.year}`} />
              <div className="overley">
                <p>{yearData.year}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Media;
