import React, { useRef } from "react";
import { ReactComponent as ArrowLeft } from "../assets/ph_arrow-circle-left-light.svg";
import { ReactComponent as ArrowRight } from "../assets/ph_arrow-circle-right-light.svg";
import "./dragging.css"


const Dragging = () => {
  const scrollingRef = useRef(null);

  const boxData = [
    {
      imgSrc: "https://i.ibb.co/f9WGPb6/1-1-1.png",
      heading: "01",
      subHeading: "Governor of the Year Award (2020)",
    },
    {
      imgSrc: "https://i.ibb.co/JQcNr29/1-1.png",
      heading: "02",
      subHeading: "Govonor of the Year Award (2021)",
    },
    {
      imgSrc: "https://i.ibb.co/f9WGPb6/1-1-1.png",
      heading: "03",
      subHeading: "Most Improved in Agriculture and Economic Empowerment",
    },
    {
      imgSrc: "https://i.ibb.co/JQcNr29/1-1.png",
      heading: "04",
      subHeading: "Youth Empowerment & Sports",
    },
    {
      imgSrc: "https://i.ibb.co/f9WGPb6/1-1-1.png",
      heading: "05",
      subHeading: "Agriculture & Food Security"
    },
  ];

  const nextSlide = () => {
    if (scrollingRef.current) {
      scrollingRef.current.scrollLeft += 500;
    }
  };

  const prevSlide = () => {
    if (scrollingRef.current) {
      scrollingRef.current.scrollLeft -= 500;
    }
  };

  return (
    <div className="about-slider-middle">
      <div className="btn">
        <ArrowLeft onClick={prevSlide} />
        <ArrowRight onClick={nextSlide} />
      </div>
      <div className="scrolling" ref={scrollingRef}>
        {boxData.map((box, index) => (
          <div key={index} className="box">
            <img src={box.imgSrc} alt="" />
            <div className="below">
            <h3>{box.heading}</h3>
              <h3>{box.subHeading}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dragging;
