import { useQuery } from "react-query";
import axios from "axios";

const useSpeech = (page = 1, year, searchQuery) => {
  const { isLoading, isError, data, error, refetch } = useQuery(
    ["speeches", page, year, searchQuery],
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/speech/speeches`, {
          params: { 
            page, 
            limit: 12, 
            year, 
            search: searchQuery 
          }
        }
      );
      return response.data;
    },
    { keepPreviousData: true }
  );

  return {
    isLoading,
    isError,
    data,
    error,
    refetch,
  };
};

export default useSpeech;
