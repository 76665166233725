import React from "react";
import {ReactComponent as Arrow} from "../../assets/Layer_13x.svg"
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./Development.css";

const Development = () => {
  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);
  return (
    <div className="development">
      <div className="image">
        <img src="https://i.ibb.co/n0Zsw6v/DSC00123-2.png" alt="" />
      </div>
      <div className="infrastructure-develop">
       <div className="card-1">
       <div className="dev-line"></div>
        <h3 data-aos="zoom-in">INFRASTRUCTURE DEVELOPMENT</h3>
        <p data-aos="zoom-in">
          The most apparent indication of Prince Dapo Abiodun’s stellar
          governance of Ogun State since 29 May 2019, is the distribution of
          infrastructural projects across all senatorial districts.
          Infrastructure development is the first among the five development
          pillars of the Prince Abiodun Administration, popularly known as
          ISEYA. By this Administration’s reckoning, infrastructural development
          includes construction of durable roads, bridges, and culverts in all
          nooks and crannies of the State; rehabilitation and maintenance of
          existing roads; provision of electricity and potable water;
          construction of public utilities such as hospitals and health centres;
          linkage of the state to other states to enhance economic development
          of the State, and to create easy access to institutions of learning
          across the State. The Administration creates jobs for the youth during
          road construction and maintenance. It has constructed chemically
          treated roads across the rural areas for easy transportation of farm
          produce from rural to urban centres.
        </p>
        <span>
        <p data-aos="zoom-in">
          In the last four years, Prince Dapo Abiodun has left no stone unturned
          in ensuring a better standard of living for inhabitants of the State
          through various infrastructural projects. Local and foreign investors
          have pitched their tents in the State, and local market men and women,
          farmers and artisans, tradesmen and business entrepreneurs now have a
          new lease of life. They transact businesses and move goods and
          services from their farms to the markets and from rural communities to
          cities with ease. Expectedly, infrastructural development has reduced
          the loss of man-hours occasioned by bad roads and poor drainage
          systems
        </p>
        </span>
       </div>
       <div className="card-2">
        <Arrow/>
       </div>
      </div>
      <div className="wrapper">
        <div className="card-1">
            <img src="https://i.ibb.co/0Yqtzq3/DJI-0853-1.png" alt="" />
        </div>
        <div className="card-2">
            <img src="https://i.ibb.co/BqFf23p/DSC08306-1.png" alt="" />
        </div>
      </div>
      <div className="cargo-plane">
        <img src="https://i.ibb.co/Q8PsrWD/DJI-0905-1.png" alt="" />
      </div>
      <div className="city-gate">
        <div className="card-1">
            <img src="https://i.ibb.co/HDy8xTN/DJI-0785-1.png" alt="" />
        </div>
        <div className="card-2">
            <img src="https://i.ibb.co/j38XD1N/DJI-0147-1.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Development;
