import { useQuery } from "react-query";
import axios from "axios";

const fetchLatestImages = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/media/latest-images`
  );
  return data;
};

const useLatestImages = () => {
  return useQuery(["latestImages"], fetchLatestImages);
};

export default useLatestImages;
